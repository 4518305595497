import { useAlert } from "@debitroom-software/vuetify-alert";
import i18n from "@/shared/plugins/i18n";
import { isAxiosError } from "axios";
import { useAuthorisationStore } from "@/modules/authorisation/authorisationStore";

const { showAlert, closeAlert } = useAlert();
const { t } = i18n.global as any;



let timeout: ReturnType<typeof setTimeout> | null;
let interval: ReturnType<typeof setInterval> | null;
let seconds: number = 0;

export function onResponseFailed (error: any) {
  if (isAxiosError(error) && error.response && error.response.status === 429) {
    seconds = error.response.headers["retry-after"];
    set429Alert(seconds);

    if (timeout) clearTimeout(timeout);
    if (interval) clearTimeout(interval);

    timeout = setTimeout(() => {
      closeAlert();
      if (interval) clearInterval(interval);
    }, seconds * 1000);


    interval = setInterval(() => {
      seconds--;
      set429Alert(seconds);
    },
    1000);
  }
  if (isAxiosError(error) && error.response && error.response.status === 401) {
    const authorisationStore = useAuthorisationStore();
    authorisationStore.accessToken = "";
    window.location.reload();
  }

  return Promise.reject(error);
}

function set429Alert (seconds: number) {
  showAlert({
    title: t("global.errors.titles.429"),
    message: t("global.errors.messages.429", { seconds }),
    hasCloseButton: false,
  });
}
