export default {
  default: [
    {
      path: "spaarder/:funderId/account/aanmaken",
      name: "accounts.create",
      component: () => import("./views/AccountFormView.vue"),
    },
    {
      path: "spaarder/:funderId/bedrijfs-account/aanmaken",
      name: "businessAccount.create",
      component: () => import("./views/AccountFormView.vue"),
    },
    {
      path: "spaarder/:funderId/account/:id/wijzigen",
      name: "accounts.edit",
      component: () => import("./views/AccountFormView.vue"),
    },
    {
      path: "spaarder/:funderId/bedrijfs-account/:id/wijzigen",
      name: "businessAccount.edit",
      component: () => import("./views/AccountFormView.vue"),
    },
  ],
};
