import { useI18n } from "vue-i18n";

export default function useScopedTranslations (prefix: string) {
  const i18n = useI18n();

  function translate (key: string, args?: any) {
    if (!i18n.te(`${prefix}.${key}`) && i18n.te(`global.${key}`)) {
      return i18n.t(`global.${key}`, args) as string;
    }
    return i18n.t(`${prefix}.${key}`, args) as string;
  }

  function translationExists (key: string) {
    return i18n.te(`${prefix}.${key}`);
  }

  function translateWithFallback (key: string, fallback: string, args?: any) {
    if (translationExists(key) ||
      (!translationExists(fallback) && translationExists(`global.${key}`))) {
      return translate(key, args);
    }
    return translate(fallback, args);
  }

  return {
    ...i18n,
    t: translate,
    gt: i18n.t,
    gte: i18n.te,
    te: translationExists,
    translateWithFallback,
  };
}
