import type { RouteRecordRaw } from "vue-router";

export default function routeBuilder (template: string = "default") {
  const moduleRoutes = import.meta.glob<{ default: RouteRecordRaw[] }>(
    "@/modules/*/routes.ts",
    { eager: true },
  );

  return Object.values(moduleRoutes).reduce<RouteRecordRaw[]>((acc, i) => {
    if (!(i.default as any)[template]) return acc;
    return acc.concat((i.default as any)[template]);
  }, []);
}
