import { defineStore } from "pinia";
import { User } from "oidc-client-ts";
import { jwtDecode } from "jwt-decode";
import { Permission, Role, Token } from "./types";
import { userManager } from "@/modules/authorisation/userManager";
import router from "@/shared/router";
import getProfile from "@/modules/authorisation/api/getProfile";
import { Profile, Agreements } from "@/shared/types";


export const useAuthorisationStore = defineStore({
  id: "authorisation",
  persist: true,
  state: (): {
    // Take in mind, that everything new added to tist list, should be optional. Since this state is persistend.
    accessToken: string;
    user?: {
      email?: string;
      name?: string;
      permissions?: Permission[];
      roles?: Role[];
      featureFlags?: { [key: string]: boolean };
    };
    profile?: Profile;
    userPreferences?: Record<string, any>;
    agreements?: Agreements;
  } => ({
    accessToken: "",
    user: undefined,
    userPreferences: { isDarkMode: null },
  }),
  actions: {
    afterLogin (user: User) {
      this.accessToken = user.access_token;
      const payload = jwtDecode<Token>(this.accessToken);
      this.user = {
        email: user.profile.email,
        name: user.profile.name,
        permissions: payload.permissions,
        roles: payload.roles,
        featureFlags: Object.entries(payload?.feature_flags || {}).map(([key, value]) => ({ [key]: value.v })).
          reduce((acc, cur) => ({
            ...acc,
            ...cur,
          }), {}),
      };
      this.user.name = user.profile.name;
      this.fetchProfileInformation();
    },
    async signIn () {
      return userManager.signinRedirect({ extraQueryParams: { audience: "https://api.debitroom" } });
    },
    async signOut () {
      this.accessToken = "";
      localStorage.clear();
      return userManager.signoutRedirect(
        { post_logout_redirect_uri: window.location.origin + router.resolve({ name: "auth.login" }).href },
      );
    },
    setUserPreference (key: string, value: any) {
      if (!this.userPreferences) {
        this.userPreferences = {};
      }
      this.userPreferences[key] = value;
    },
    async fetchProfileInformation (force: boolean = false) {
      if (this.profile && !force) return;
      const response = await getProfile();
      this.profile = response.data.profile;
      this.agreements = response.data.agreements;
    },
  },
  getters: {
    hasFeature (state) {
      return (flag: string) => state.user?.featureFlags?.[flag] ?? false;
    },
    hasRole (state) {
      return (role: string) => state.user?.roles?.some(({ key }) => key === role) ?? false;
    },
  },
});
