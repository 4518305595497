export default {
  unauthorised: [
    {
      path: "/auth/login",
      name: "auth.login",
      component: () => import("@/modules/authorisation/views/LoginView.vue"),
    },
    {
      path: "/auth/login-callback",
      name: "auth.loginCallback",
      component: () => import("@/modules/authorisation/views/LoginCallbackView.vue"),
    },
  ],
  default: [
    {
      path: "/auth/logout",
      name: "auth.logout",
      component: () => import("@/modules/authorisation/views/LogoutView.vue"),
    },
  ],
};
