import type { IconAliases } from "vuetify";

import { faArrowsAltV,
  faBackwardStep,
  faBars,
  faCalendarAlt,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle as faSolidCircle,
  faExclamation,
  faForwardStep,
  faInfoCircle,
  faMinus,
  faPaperclip,
  faPencil,
  faPlus,
  faSort,
  faSortDown,
  faSortUp,
  faSquareCheck,
  faSquareMinus,
  faStar,
  faStarHalf,
  faSync,
  faTrashCan,
  faTriangleExclamation,
  faXmark } from "@fortawesome/pro-solid-svg-icons";

import { faCircle, faCircleDot, faSquare } from "@fortawesome/pro-regular-svg-icons";

const icons = [
  faMinus,
  faPlus,
  faPaperclip,
  faArrowsAltV,
  faForwardStep,
  faBackwardStep,
  faSync,
  faStar,
  faStarHalf,
  faPencil,
  faCircle,
  faSolidCircle,
  faCaretDown,
  faBars,
  faChevronDown,
  faChevronUp,
  faSort,
  faSortUp,
  faSortDown,
  faSquareMinus,
  faSquare,
  faSquareCheck,
  faChevronRight,
  faChevronLeft,
  faTriangleExclamation,
  faExclamation,
  faInfoCircle,
  faCheckCircle,
  faXmark,
  faCheck,
  faTrashCan,
  faCircleDot,
  faCalendarAlt,
];

const aliases: IconAliases = {
  collapse: "fas fa-chevron-up",
  complete: "fas fa-check",
  cancel: "far fa-xmark",
  close: "far fa-xmark",
  delete: "fas fa-trash-can",
  clear: "fas fa-xmark",
  success: "fas fa-check-circle",
  info: "fas fa-info-circle",
  warning: "fas fa-exclamation",
  error: "fas fa-triangle-exclamation",
  prev: "fas fa-chevron-left",
  next: "fas fa-chevron-right",
  checkboxOn: "fas fa-square-check",
  checkboxOff: "far fa-square",
  checkboxIndeterminate: "fas fa-square-minus",
  delimiter: "fas fa-circle",
  sort: "fas fa-sort",
  sortAsc: "fas fa-sort-up",
  sortDesc: "fas fa-sort-down",
  expand: "fas fa-chevron-down",
  menu: "fas fa-bars",
  subgroup: "fas fa-caret-down",
  dropdown: "fas fa-caret-down",
  radioOn: "far fa-circle-dot",
  radioOff: "far fa-circle",
  edit: "fas fa-pencil",
  ratingEmpty: "fas fa-star",
  ratingFull: "fas fa-star",
  ratingHalf: "fas fa-star-half",
  loading: "fas fa-sync",
  first: "fas fa-backward-step",
  last: "fas fa-forward-step",
  unfold: "fas fa-arrows-alt-v",
  file: "fas fa-paperclip",
  plus: "fas fa-plus",
  minus: "fas fa-minus",
  calendar: "fas fa-calendar-alt",
};

export default {
  aliases,
  icons,
};
