import checkPartnerAgreementStatus from "@/shared/router/guards/checkPartnerAgreementStatus";

export default {
  default: [
    {
      path: "/spaar-contracten",
      name: "fundingContracts.table",
      component: () => import("./views/FundingContractTableView.vue"),
      beforeEnter: checkPartnerAgreementStatus,
    },
    {
      path: "/spaar-contract/aanmaken",
      name: "fundingContracts.create",
      component: () => import("./views/FundingContractFormView.vue"),
      beforeEnter: checkPartnerAgreementStatus,
    },
    {
      path: "/spaar-contract/:id/wijzigen",
      name: "fundingContracts.edit",
      component: () => import("./views/FundingContractFormView.vue"),
      beforeEnter: checkPartnerAgreementStatus,
    },
    {
      path: "/spaar-contract/:id",
      name: "fundingContracts.view",
      component: () => import("./views/FundingContractDetailsView.vue"),
      beforeEnter: checkPartnerAgreementStatus,
    },
  ],
};
