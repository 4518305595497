import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useAuthorisationStore } from "@/modules/authorisation/authorisationStore";
import { AgreementStatus } from "@debitroom-software/types";

export default async function checkPartnerAgreementStatus (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const authorisationStore = useAuthorisationStore();
  await authorisationStore.fetchProfileInformation();
  if (authorisationStore.agreements?.funderAgreementStatus !== AgreementStatus.Signed) {
    return next({ name: "noFunderAgreementView" });
  }
  next();
}
