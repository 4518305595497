import { createVuetify } from "vuetify";
import "vuetify/styles";
import { fa } from "vuetify/iconsets/fa-svg";
import { aliases } from "./icons";
import { nl } from "vuetify/locale";

export default createVuetify({
  icons: {
    defaultSet: "fa",
    aliases,
    sets: { fa },
  },
  locale: {
    locale: "nl",
    messages: { nl },
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#2A455E",
          secondary: "#122338",
          accent: "#39838E",
          secondaryaccent: "#B2DD00",
          background: "#FFFFFF",
          surface: "#FFFFFF",
          "on-surface": "#122338",
          "on-background": "#122338",
        },
        variables: {
          "border-color": "#000000",
          // 'border-opacity': 0.12,
          // "high-emphasis-opacity": 0.87,
          // 'medium-emphasis-opacity': 0.60,
          // 'disabled-opacity': 0.38,
          // 'idle-opacity': 0.04,
          "hover-opacity": 0.08,
          // 'focus-opacity': 0.12,
          // 'selected-opacity': 0.08,
          // 'activated-opacity': 0.12,
          // 'pressed-opacity': 0.12,
          // 'dragged-opacity': 0.08,
          // 'theme-kbd': '#212529',
          // 'theme-on-kbd': '#FFFFFF',
          // 'theme-code': '#F5F5F5',
          // 'theme-on-code': '#000000',
        },
      },
      dark: {
        colors: {
          primary: "#2A455E",
          secondary: "#122338",
          accent: "#39838E",
          secondaryaccent: "#B2DD00",
          surface: "#121212",
        },
      },
    },
  },
  defaults: {
    global: { ripple: false },
    VTextField: {
      variant: "outlined",
      density: "compact",
    },
    VFileInput: {
      variant: "outlined",
      density: "compact",
    },
    VAutocomplete: {
      variant: "outlined",
      density: "compact",
    },
    VSelect: {
      variant: "outlined",
      density: "compact",
    },
    VCard: {
      VCardActions: {
        class: "justify-center",
        VBtn: {
          slim: false,
          variant: "flat",
        },
      },
    },
    VBtn: { variant: "flat" },
    VTabs: { bgColor: "rgba(var(--v-theme-accent), 0.15)" },
  },
});
