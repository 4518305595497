export function addModuleTranslationsToMessages (
  messages: Record<string, Record<string, any>>,
) {
  const translations = import.meta.glob<Record<string, any>>(
    "@/modules/*/translations/*.json",
    { eager: true },
  );
  Object.entries(translations).forEach(([path, object]) => {
    const regexResults = path.match(/(\w+)\/translations\/([\w-]+).json/);
    if (!regexResults) return;

    const module = regexResults[1];
    const locale = regexResults[2];

    messages[locale] = messages[locale] ?? {};
    messages[locale][module] = object.default;
  });
}

export function addApplicationTranslationsToMessages (
  messages: Record<string, Record<string, any>>,
) {
  const translations = import.meta.glob<Record<string, any>>(
    "@/shared/translations/*/*.json",
    { eager: true },
  );
  Object.entries(translations).forEach(([path, object]) => {
    const regexResults = path.match(/translations\/(\w+)\/([\w-]+).json/);
    if (!regexResults) return;

    const module = regexResults[1];
    const locale = regexResults[2];

    messages[locale] = messages[locale] ?? {};
    messages[locale][module] = object.default;
  });
}
