import { library } from "@fortawesome/fontawesome-svg-core";
import appIcons from "./app";
import vuetifyIcons from "./vuetify";

const icons = [
  ...appIcons.icons,
  ...vuetifyIcons.icons,
];

library.add(...icons);

const aliases = {
  ...vuetifyIcons.aliases,
  ...appIcons.aliases,
};

export { aliases };
