import checkPartnerAgreementStatus from "@/shared/router/guards/checkPartnerAgreementStatus";

export default {
  default: [
    {
      path: "/dashboard",
      name: "dashboard",
      redirect: { name: "funders.table" },
    },
    {
      path: "/spaarder",
      name: "funders.table",
      component: () => import("./views/FunderTableView.vue"),
      beforeEnter: checkPartnerAgreementStatus,
    },
    {
      path: "/spaarder/aanmaken",
      name: "funders.create",
      component: () => import("./views/FunderFormView.vue"),
      beforeEnter: checkPartnerAgreementStatus,
    },
    {
      path: "/spaarder/:id",
      name: "funders.view",
      component: () => import("./views/FunderDetailsView.vue"),
      redirect: { name: "funders.view.contracts" },
      beforeEnter: checkPartnerAgreementStatus,
      children: [
        {
          path: "contracten",
          name: "funders.view.contracts",
          component: () => import("./views/FunderDetails/ContractsView.vue"),
        },
        {
          path: "accounts/:accountId",
          name: "funders.view.accounts.view",
          component: () => import("./views/FunderDetails/AccountView.vue"),
        },
        {
          path: "accounts",
          name: "funders.view.accounts",
          component: () => import("./views/FunderDetails/AccountsView.vue"),
        },
        {
          path: "details",
          name: "funders.view.details",
          component: () => import("./views/FunderDetails/DetailsView.vue"),
        },
      ],
    },
    {
      path: "/spaarder/:id/wijzigen",
      name: "funders.edit",
      component: () => import("./views/FunderFormView.vue"),
      beforeEnter: checkPartnerAgreementStatus,
    },
    {
      path: "/geen-contract",
      name: "noFunderAgreementView",
      component: () => import("@/shared/views/NoFunderAgreementView.vue"),
    },
  ],
};
