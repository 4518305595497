import { registerPlugins } from "@/shared/plugins";
import App from "./App.vue";
import { createApp } from "vue";
import "@/styles/main.scss";

const app = createApp(App);

registerPlugins(app);

app.mount("#app");
