import { useAuthorisationStore } from "@/modules/authorisation/authorisationStore";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default function redirectToLoginIfUnauthorised (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const authorisationStore = useAuthorisationStore();
  if (!authorisationStore.accessToken) {
    return next({ name: "auth.login" });
  }
  next();
}
