import { addApplicationTranslationsToMessages,
  addModuleTranslationsToMessages } from "@/shared/plugins/i18n/util/addModuleTranslations";

import { createI18n } from "vue-i18n";

const messages: Record<string, Record<string, any>> = {};

addModuleTranslationsToMessages(messages);
addApplicationTranslationsToMessages(messages);
export default createI18n({
  legacy: false,
  locale: "nl-NL",
  fallbackLocale: "nl-NL",
  messages,
  datetimeFormats: {
    "nl-NL": {
      dateTime: {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      month: { month: "long" },
      monthDay: {
        month: "long",
        day: "numeric",
      },
    },
  },
  numberFormats: {
    "nl-NL": {
      currency: {
        style: "currency",
        currency: "EUR",
      },
      currencyNoDecimals: {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      currencyShort: {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
        notation: "compact",
        compactDisplay: "short",
      },
      percent: {
        style: "percent",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percentShort: {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        notation: "compact",
        compactDisplay: "short",
      },
    },
  },
});
